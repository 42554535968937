<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Kontragent Shartnoma Ozgartirish</h3>
            </div>
          </div>

          <div class="card-body">
            <div class="input__wrapper">
              <div class="col-3">
                <v-text-field
                  label="Reg raqam"
                  outlined
                  dense
                  v-model="contragentContract.reg_number"
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  label="Summa"
                  outlined
                  dense
                  v-currency="options"
                  v-model="contragentContract.amount"
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="reg_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reg_date"
                      label="Reg sana"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="reg_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="modal = false"
                      >Bekor qilish</v-btn
                    >
                    <v-btn
                      small
                      color="success"
                      @click="$refs.dialog.save(reg_date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
              <div class="col-3">
                <v-dialog
                  ref="dialog2"
                  v-model="modal2"
                  :return-value.sync="end_date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_date"
                      label="Tugash sanasi"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="end_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="modal2 = false"
                      >Bekor qilish</v-btn
                    >
                    <v-btn
                      small
                      color="success"
                      @click="$refs.dialog2.save(end_date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
            <div>
              <v-btn
                small
                color="success"
                @click="update(contragentContract.id)"
                >Yuborish</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'ContractUpdate',
  data: () => ({
    reg_date: '',
    end_date: '',
    amount: '',
    modal: false,
    modal2: false,
    // mask
    value: null,
    locale: 'default',
    selectedCurrencyOption: 1,
    currencyCode: 'EUR',
    distractionFree: false,
    hideCurrencySymbol: true,
    hideGroupingSymbol: true,
    hideNegligibleDecimalDigits: true,
    prefix: null,
    suffix: null,
    precisionEnabled: true,
    precisionRangeEnabled: false,
    precisionFixed: 0,
    precisionRange: [0, 20],
    valueRangeEnabled: false,
    valueRange: [0, 9999],
    minActive: false,
    maxActive: false,
    autoDecimalMode: false,
    valueAsInteger: false,
    allowNegative: false,
    placement_expenditure: null
  }),

  created() {
    this.$store.dispatch('getCountragentKontracts', this.$route.params.id)
  },
  watch: {
    contragentContract: {
      handler(val) {
        if (val.reg_date !== undefined || val.end_date !== undefined) {
          this.reg_date = val.reg_date
          this.end_date = val.end_date
          this.amount = val.amount
        }
      },
      deep: true
    }
  },
  computed: {
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    contragentContract() {
      const data = this.$store.state.requests.countragentKontracts

      if (data.amount !== undefined && data.amount !== null)
        data.amount = data.amount.toLocaleString('es-US')
      return data
    }
  },
  methods: {
    update(value) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.amount)) {
        this.amount = parseFloat(this.amount.replace(/[ ,]/g, ''), 10)
      } else {
        this.amount = null
      }
      const data = {
        reg_number: this.contragentContract.reg_number,
        reg_date: this.reg_date,
        end_date: this.end_date,
        amount: this.amount
      }
      this.$store.dispatch('UpdateCountragentKontracts', { value, data })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kontragentlar' },
      { title: 'Kontragentlar Shartnoma Ozgartirish' }
    ])
  }
}
</script>
<style scoped>
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.col-3 {
  padding-left: 0;
}
</style>
